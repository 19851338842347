import React, { useState, Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'

import '../sass/subPage/subPage.scss'

// import Student from "../components/subPage/Student";
import Tutor from '../components/subPage/Tutor'
import Academy from '../components/subPage/Academy'
import Platform from '../components/subPage/Platform'
import Promotion from '../components/subPage/Promotion'
import MyApp from '../components/subPage/MyApp'
import Classmodel from '../components/subPage/Classmodel'
import AiTutor from '../components/subPage/AiTutor'

let Student = lazy(() => {
  return import('../components/subPage/Student')
})

function SubPage() {
  const [suspenseStyle, setSuspenseStyle] = useState({
    textAlign: 'center',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#eee',
  })

  return (
    <>
      <Switch>
        <Route exact path="/ai">
          <Suspense
            fallback={
              <div style={suspenseStyle}>
                <img src="/image/loding.gif" alt="" />
              </div>
            }
          >
            <AiTutor></AiTutor>
          </Suspense>
        </Route>
        <Route exact path="/student">
          <Suspense
            fallback={
              <div style={suspenseStyle}>
                <img src="/image/loding.gif" alt="" />
              </div>
            }
          >
            <Student></Student>
          </Suspense>
        </Route>
        <Route exact path="/tutor">
          <Suspense
            fallback={
              <div style={suspenseStyle}>
                <img src="/image/loding.gif" alt="" />
              </div>
            }
          >
            <Tutor></Tutor>
          </Suspense>
        </Route>
        <Route exact path="/academy">
          <Suspense
            fallback={
              <div style={suspenseStyle}>
                <img src="/image/loding.gif" alt="" />
              </div>
            }
          >
            <Academy></Academy>
          </Suspense>
        </Route>
        <Route exact path="/platform">
          <Suspense
            fallback={
              <div style={suspenseStyle}>
                <img src="/image/loding.gif" alt="" />
              </div>
            }
          >
            <Platform></Platform>
          </Suspense>
        </Route>
        <Route exact path="/promotion">
          <Suspense
            fallback={
              <div style={suspenseStyle}>
                <img src="/image/loding.gif" alt="" />
              </div>
            }
          >
            <Promotion></Promotion>
          </Suspense>
        </Route>
        <Route exact path="/myapp">
          <Suspense
            fallback={
              <div style={suspenseStyle}>
                <img src="/image/loding.gif" alt="" />
              </div>
            }
          >
            <MyApp></MyApp>
          </Suspense>
        </Route>
        <Route exact path="/classmodel">
          <Suspense
            fallback={
              <div style={suspenseStyle}>
                <img src="/image/loding.gif" alt="" />
              </div>
            }
          >
            <Classmodel></Classmodel>
          </Suspense>
        </Route>
      </Switch>
    </>
  )
}

export default SubPage
