import React, { useState } from "react";
import Slider from "react-slick";
import 'animate.css';

import "../../sass/subPage/slickHome.scss";

function SlickHome() {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [afterCurrentSlide, setAfterCurrentSlide] = useState(1);

  let settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: (current, next) => {
      setCurrentSlide(next + 1);
    },
    afterChange: current => {
      setAfterCurrentSlide(current + 1)
    }
  };

  const pageList = [
    { name: '메인', src: 'image/slick/slide1.png' },
    { name: '개념학습', src: 'image/slick/slide2.png', video: 'conceptLearning' },
    { name: '기초계통', src: 'image/slick/slide3.png' },
    { name: '풀이영상', src: 'image/slick/slide4.png' },
    { name: 'AI튜터', src: 'image/slick/slide5.png', video: 'aiTutor' },
    { name: '맞춤 피드백', src: 'image/slick/slide6.png' },
    { name: '진도분석', src: 'image/slick/slide7.png' },
    { name: '안내', src: 'image/slick/slide8.png' }
  ]
  const conceptStepData = [
    {
      title: '교실학습',
      content: '담당교사'
    },
    {
      title: '방과후학습',
      content: 'AI개념'
    },
    {
      title: '방과후학습',
      content: 'AI질문'
    },
    {
      title: '방과후학습',
      content: '인강'
    },
    {
      title: '메타인지학습',
      content: '전자칠판에 설명'
    }
  ]
  const reviewFlowChartData1 = [
    '고1\n이차방정식',
    '기초계통\n클릭',
    '중3\n이차방정식',
    '기초계통\n클릭',
    '중2\n연립방정식',
    '기초계통\n클릭'
  ]
  const reviewFlowChartData2 = [
    '중1\n일차방정식',
    '심화계통\n클릭',
    '중2\n연립방정식',
    '심화계통\n클릭',
    '중3\n이차방정식',
    '심화계통\n클릭'
  ]

  const aiIconAnimation = () => {
    return (
      <img src={ 'image/slick/AI_icon.png' } alt={ 'aiIcon' } className={ 'aiIcon' } style={{
        position: 'absolute',
        left: '24.3%',
        bottom: '32.5%',
        width: '3.5vw',
        animation: 'pulse',
        animationDuration: '0.5s',
        animationDelay: '0.5s',
        animationIterationCount: 'infinite'
      }} />
    )
  }
  const conceptLearningVideo = () => {
    return (
      <div>
        <div className={ 'conceptLearningWrap1' }>
          <video autoPlay controls loop muted className={ 'video conceptLearningVideo' }>
            <source src="https://homepage-math-video.s3.ap-northeast-2.amazonaws.com/conceptVideo1.mp4" type="video/mp4"/>
          </video>
        </div>

        <div className={ 'conceptLearningWrap2' }>
          <video autoPlay controls loop muted className={ 'video conceptLearningVideo' }>
            <source src="https://homepage-math-video.s3.ap-northeast-2.amazonaws.com/conceptVideo2.mp4" type="video/mp4"/>
          </video>
        </div>

        <div className={ 'conceptLearningWrap3' }>
          <video autoPlay controls loop muted className={ 'video conceptLearningVideo' }>
            <source src="https://homepage-math-video.s3.ap-northeast-2.amazonaws.com/conceptVideo3.mp4" type="video/mp4"/>
          </video>
        </div>

        { currentSlide === 2 && (
          <div className={ 'conceptContainer' }>
            { conceptStepData.map((step, index) => {
                return (
                  <div key={ index } className={ 'conceptStepBox' } style={{
                    animation: 'fadeInDown',
                    animationDuration: `1s`,
                    animationDelay: `${ index + 1 }s`
                  }}>
                    <div className={ 'conceptStepTitle' }>{ index + 1 }차: { step.title }</div>
                    <div className={ 'conceptStepContent' }>{ step.content }</div>
                  </div>
                )
              }
            ) }
          </div>)
        }
      </div>
    )
  }

  const reviewFlowChart = () => {
    return (
      <div style={{ animation: 'fadeIn', animationDuration: '1s' }}>
        <div className={ 'flowChartContainer1' }>
          { reviewFlowChartData1.map((data, index) => {
            return (
              <div key={ index } className={ 'flowChartBox' } style={{
                animation: 'fadeInDown',
                animationDuration: `1s`,
                animationDelay: `${ index + 1 }s`
              }}>
                <div className={ 'flowChartTitle' }>{ data }</div>
              </div>
            )
          }) }


        </div>

        <div className={ 'flowChartContainer2' }>
          { reviewFlowChartData2.map((data, index) => {
            return (
              <div key={ index } className={ 'flowChartBox' } style={{
                animation: 'fadeInDown',
                animationDuration: `1s`,
                animationDelay: `${ index + 7 }s`
              }}>
                <div className={ 'flowChartTitle' }>{ data }</div>
              </div>
            )
          }) }
        </div>
      </div>
    )
  }

  const aiTutorialVideo = () => {
    return (
      <div>
        <div className={ 'aiTutorVideoWrap1' }>
          <video autoPlay controls loop muted className={ 'video aiTutorVideo' }>
            <source src="https://homepage-math-video.s3.ap-northeast-2.amazonaws.com/aiTutorVideo1.mp4" type="video/mp4"/>
          </video>
        </div>

        <div className={ 'aiTutorVideoWrap2' }>
          <video autoPlay controls loop muted className={ 'video aiTutorVideo' }>
            <source src="https://homepage-math-video.s3.ap-northeast-2.amazonaws.com/aiTutorVideo2.mp4" type="video/mp4"/>
          </video>
        </div>
      </div>
    )
  }

  return (
    <div className={ 'container' }>
      <Slider { ...settings } debounce={300}>
        { pageList.map((page, index) => {
          return (
            <div key={ index } className={ 'contentWrap' }>
              <img src={ page.src } alt={ page.name } className={ 'images' }/>
              { (currentSlide === 1) && aiIconAnimation() }
              { page.video === 'conceptLearning' && conceptLearningVideo() }
              { (currentSlide === 3) && reviewFlowChart() }
              { page.video === 'aiTutor' && aiTutorialVideo() }
            </div>
          )
        }) }
      </Slider>
    </div>
  );
}

export default SlickHome;
