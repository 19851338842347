import '../../sass/subPage/aiTutor.scss'

function AiTutor() {
  return (
    <>
      <div className="ai-tutor">
        <div className="wrap">
          <div className="ai-header">
            수학문제 사진만 찍으면
            <br />
            AI가 문제풀이를 상세하게!!
          </div>
          <div className="ai-flexbox">
            <div className="left-box">
              <img
                src="/image/subPage/student/shop.png"
                alt=""
                class="iconimage01 none"
              />
              <div className="inner-box">
                <div className="image-clip">
                  <img src="/image/main/main_phone01.png" alt="" />
                  <div className="screen">
                    <img src="/image/display1.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="right-box">
              <img
                src="/image/subPage/student/sailor.png"
                alt=""
                class="iconimage02 none"
              />
              <div className="inner-box">
                <div className="image-clip">
                  <img src="/image/main/main_phone01.png" alt="" />
                  <div className="screen">
                    <video
                      className="display"
                      src="/video/q18.mp4"
                      autoPlay
                      loop
                      muted
                    ></video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ai-tutor ai-tutor02">
        <div className="wrap">
          <div className="ai-header">
            수학문제 사진만 찍으면
            <br />
            AI가 문제풀이를 상세하게!!
          </div>
          <div className="ai-flexbox">
            <div className="left-box">
              <img
                src="/image/subPage/student/shop.png"
                alt=""
                class="iconimage01 none"
              />
              <div className="inner-box">
                <div className="image-clip">
                  <img src="/image/main/main_phone01.png" alt="" />
                  <div className="screen">
                    <img src="/image/display2.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="right-box">
              <img
                src="/image/subPage/student/sailor.png"
                alt=""
                class="iconimage02 none"
              />
              <div className="inner-box">
                <div className="image-clip">
                  <img src="/image/main/main_phone01.png" alt="" />
                  <div className="screen">
                    <video
                      className="display"
                      src="/video/q8.mp4"
                      autoPlay
                      loop
                      muted
                    ></video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AiTutor
