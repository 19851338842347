import React, { useEffect } from "react";
import "../../sass/subPage/tutor.scss";

function Tutor() {
  const imageUrl = (image) => {
    return `/image/subPage/tutor/${image}.png`;
  }
  useEffect(() => {
    setTimeout(() => {
      document.getElementById(`scroll01`).classList.add("on");
    }, 100)
  })
  window.addEventListener("scroll", () => {
    for (let i = 1; i < 3; i++) {
      if (document.getElementById(`scroll0${i}`).getBoundingClientRect().top < 200) {
        document.getElementById(`scroll0${i}`).classList.add("on");
      }
    }
  })

  return (
    <div className="tutor">
      <div id="scroll01">
        <div className="wrap">
          <h3 className="title font">
            본사제공 전자칠판을 이용한 <br />
            <span>스마트한 답변</span>
          </h3>

          <div className="book">
            <div className="play">
              <img src={imageUrl("11")} alt="" />
              <video autoPlay controls loop muted >
                <source src="/image/subPage/tutor/section01_movie.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="grid">
              <img src={imageUrl("22")} alt="" />
              <img src={imageUrl("33")} alt="" />
            </div>
          </div>

          {/* <div className="position">
            <img className="pcimg" src={imageUrl("1")} alt="" />
            <img className="moimg" style={{ width: "100%" }} src={imageUrl("mobile01")} alt="" />
            <img src={imageUrl("section01_character")} alt="" className="character01" />
            <img src={imageUrl("shop")} alt="" className="iconimage01 none"/>
          </div> */}
        </div>
      </div>

      <div className="blue" id="scroll02">
        <div className="wrap">
          <h3 className="title font">
            학생 질문비에 대한 <br />
            <span>튜터와 본사 분배비율</span>
          </h3>

          <div className="position">
            <img className="pcimg" src={imageUrl("2")} alt="" />
            <img className="moimg" style={{ width: "100%" }} src={imageUrl("mobile02")} alt="" />
            <img src={imageUrl("section02_character")} alt="" className="character02" />
            <img src={imageUrl("silor")} alt="" className="iconimage02 none" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tutor;