import React, { useState } from 'react'
import '../../sass/subPage/platform.scss'

function MyApp() {
  const [tap, setTap] = useState(0)
  const imageUrl = (image) => {
    return `/image/subPage/platform/${image}.png`
  }

  return (
    <div className="platform">
      <div className="wrap">
        <div className="tap">
          <div
            className={tap === 0 ? 'on' : ''}
            onClick={() => {
              setTap(0)
            }}
          >
            학교,학원,교습소,과외 MY APP
          </div>
          {/* <div className={tap === 1 ? "on" : ""} onClick={() => { setTap(1) }}>일반 튜터 MY APP</div> */}
        </div>
        <div
          className="page_1"
          style={tap === 0 ? { display: 'block' } : { display: 'none' }}
        >
          <h3 className="title">
            <span>학교, 학원, 교습소, 과외 MY APP</span>
            <p>나만의 앱, 우리 학원만의 앱을 구축</p>
          </h3>
          <ul>
            <li>
              <h2>나만의 문제은행을 쉽게 구축</h2>
              <p>학교별 기출문제은행 스마트하게 구축</p>
              <p>
                학생들이 질문한 문제에 대한 답변문제들이 문제은행에 자동 등록
              </p>
              <p>셀프 질문답변 문제들도 문제은행에 자동 등록</p>
            </li>
            <li>멤버로 가입한 학생은 앱 사용료 무료</li>
            <li>
              라이브 무료
              <br />
              (1:1 및 1:N 전자칠판식 수업)
            </li>
            <li>
              멤버관리
              <br />
              (그룹편성, 그룹별 쪽지, 그룹별 라이브 수업 초대 등)
            </li>
            <li>
              상단
              <img
                src="/image/hd_logo.svg"
                alt=""
                style={{ height: '30px', padding: '0px 3px' }}
              />
              로고자리에 원하는 로고로 변경
            </li>
            <li>
              학교: 학급당 월 88,000원
              <br />
              학원,교습소: 월 165,000원(부가세 포함)
            </li>
            <img src={imageUrl('shop')} alt="" className="iconimage01 none" />
            <img src={imageUrl('silor')} alt="" className="iconimage02 none" />
          </ul>

          <form action="/mail/mail.php" method="post">
            <input type="hidden" name="type" value="학원" />
            <div>
              <label>
                <span>이름</span>
                <input type="text" name="name" />
              </label>
              <label>
                <span>휴대폰</span>
                <input type="text" name="tel" />
              </label>
            </div>
            <input
              type="submit"
              value="학교, 학원, 교습소, 과외 MY APP 신청하기"
            />
          </form>
        </div>

        <div
          className="page_2"
          style={tap === 1 ? { display: 'block' } : { display: 'none' }}
        >
          <h3 className="title">
            <span>일반 튜터 MY APP</span>
            <p>나만의 앱을 구축</p>
          </h3>
          <ul>
            <li>
              <h2>나만의 문제은행을 쉽게 구축</h2>
              <p>
                학생들이 질문한 문제에 대한 답변문제들이 문제은행에 자동 등록
              </p>
              <p>셀프 질문답변 문제들도 문제은행에 자동 등록</p>
            </li>
            <li>
              라이브 무료
              <br />
              (1:1 및 1:N 전자칠판식 수업)
            </li>
            <li>
              멤버관리
              <br />
              (그룹편성, 그룹별 쪽지, 그룹별 라이브 수업 초대 등)
            </li>
            <li>
              상단
              <img
                src="/image/hd_logo.svg"
                alt=""
                style={{ height: '30px', padding: '0px 3px' }}
              />
              로고자리에 원하는 로고로 변경
            </li>
            <li>월 사용료 : 11,000원(부가세 포함)</li>
            <img src={imageUrl('shop')} alt="" className="iconimage01 none" />
            <img src={imageUrl('silor')} alt="" className="iconimage02 none" />
          </ul>

          <form action="/mail/mail.php" method="post">
            <div>
              <input type="hidden" name="type" value="튜터앱" />
              <label>
                <span>이름</span>
                <input type="text" name="name" />
              </label>
              <label>
                <span>휴대폰</span>
                <input type="text" name="tel" />
              </label>
            </div>
            <input type="submit" value="일반 튜터 MY APP 신청하기" />
          </form>
        </div>
      </div>
    </div>
  )
}

export default MyApp
