import React from "react";
import "../../sass/subPage/platform.scss";

function Platform() {
  const imageUrl = (image) => {
    return `/image/subPage/platform/${image}.png`;
  }

  return (
    <div className="platform">
      <div className="wrap">
        <h3 className="title">
          <span>튜터모집</span>
          <p>신청을 하면 사용할 수 있는 앱을 보내드립니다.</p>
        </h3>

        <ul>
          <li>문제가 전자칠판에 자동재편집!</li>
          <li>1문제당 5분 이내 답변</li>
          <li>준비시간도 필요없고, 2차관리도 없고!</li>
          <li>1시간 최대 수입 : 라이브 28,800원<br />(12문제 – 문제당 2,400원)</li>
          <li>내가 원하는 시간에, 원하는 만큼!</li>
          <li>1시간 최대 수입 : 풀이영상 21,600원<br />(12문제 – 문제당 1,800원)</li>
          <img src={imageUrl("shop")} alt="" className="iconimage01 none" />
          <img src={imageUrl("silor")} alt="" className="iconimage02 none" />
        </ul>

        <form action="/mail/mail.php" method="post">
          <input type="hidden" name="type" value=
            "튜터앱" />
          <div>
            <label>
              <span>이름</span>
              <input type="text" name="name" />
            </label>
            <label>
              <span>휴대폰</span>
              <input type="text" name="tel" />
            </label>
          </div>
          <input type="submit" value="튜터 신청하기" />
        </form>
      </div>
    </div>
  )
}

export default Platform;