import React from 'react'

function Footer() {
  return (
    <footer>
      <div className="download">
        <img src="/image/main/downloadBg.jpg" alt="" />
        <div className="content">
          <div>
            <p>
              이제 <span>매쓰튜터</span> 시작해보세요
            </p>
            <div className="sns">
              <a href="https://www.instagram.com/mathtutor_official/">
                <img src="/image/main/sns01.png" alt="" />
              </a>
              <a href="https://www.youtube.com/channel/UCLjvAyW-_bAyUmKL4jRIqHA">
                <img src="/image/main/sns02.png" alt="" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=100075069613690">
                <img src="/image/main/sns03.png" alt="" />
              </a>
              <a href="https://blog.naver.com/hpedu1">
                <img src="/image/main/sns04.png" alt="" />
              </a>
              <a href="http://pf.kakao.com/_xdkxmJb">
                <img src="/image/main/sns05.png" alt="" />
              </a>
            </div>
          </div>

          <ul>
            <li>
              <a href="https://play.google.com/store/apps/details?id=kr.mathtutor&pcampaignid=web_share">
                <img src="/image/main/google.png" alt="" />
              </a>
            </li>
            <li>
              <a href="https://apps.apple.com/kr/app/%EB%A7%A4%EC%93%B0%ED%8A%9C%ED%84%B0-%EC%88%98%ED%95%99%EB%AC%B8%EC%A0%9C-ai%ED%8A%9C%ED%84%B0%EC%99%80-%EC%9E%AC%EB%AF%B8%EC%9E%88%EA%B2%8C-%ED%95%A8%EA%BB%98-%ED%92%80%EA%B8%B0/id6444154902">
                <img src="/image/main/app.png" alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* <div className="top">
        <div className="wrap">

        </div>
      </div> */}
      <div className="bottom">
        <div className="wrap">
          <div>
            <a href="/" id="logo">
              <img src="/image/ft_logo.svg" alt="" />
            </a>

            <div>
              <p>
                (주)해피에듀테크 l 대표이사 : 남문식 <br />
                사업자등록번호 : 595-81-01622 <br />
                대구광역시 수성구 상록로 8-13, 502호(범어동, 네오빌) <br />
                TEL. 053-253-0678 l FAX. 053-253-0687 l MAIL. hpedu2@gmail.com
              </p>
              <p>Copyright@해피에듀테크 All Right Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
