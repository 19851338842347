/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

function Header() {
  const [menuOn, setMenuOn] = useState(false)
  useEffect(() => {
    setMenuOn(false)
  }, [useParams()])

  return (
    <header>
      <div className="wrap">
        <div className="flexBox">
          <a href="/" id="logo">
            <img src="/image/hd_logo.svg" alt="" />
          </a>

          <nav className="menu pc">
            <Menu></Menu>
          </nav>

          <div className="sns pc">
            <a href="https://www.instagram.com/mathtutor_official/">
              <img src="/image/hd_sns01.png" alt="" />
            </a>
            <a href="https://www.youtube.com/channel/UCLjvAyW-_bAyUmKL4jRIqHA">
              <img src="/image/hd_sns02.png" alt="" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100075069613690">
              <img src="/image/hd_sns03.png" alt="" />
            </a>
            <a href="https://blog.naver.com/hpedu1">
              <img src="/image/hd_sns04.png" alt="" />
            </a>
            <a href="http://pf.kakao.com/_xdkxmJb">
              <img src="/image/hd_sns05.png" alt="" />
            </a>
          </div>

          <div className="mobile">
            <div
              className="button"
              onClick={() => {
                setMenuOn(!menuOn)
              }}
            >
              <i class="fa-solid fa-bars"></i>
            </div>
            <div
              className={menuOn === false ? 'bg' : 'bg on'}
              onClick={() => {
                setMenuOn(false)
              }}
            ></div>
            <div className={menuOn === false ? 'menuBox' : 'menuBox on'}>
              <a href="/" className="logo">
                <img src="/image/ft_logo.svg" alt="" />
              </a>
              <Menu></Menu>
              <div className="sns">
                <a href="https://www.instagram.com/mathtutor_official/">
                  <img src="/image/main/sns01.png" alt="" />
                </a>
                <a href="https://www.youtube.com/channel/UCLjvAyW-_bAyUmKL4jRIqHA">
                  <img src="/image/main/sns02.png" alt="" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=100075069613690">
                  <img src="/image/main/sns03.png" alt="" />
                </a>
                <a href="https://blog.naver.com/hpedu1">
                  <img src="/image/main/sns04.png" alt="" />
                </a>
                <a href="http://pf.kakao.com/_xdkxmJb">
                  <img src="/image/main/sns05.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

function Menu() {
  const menuList = [
    {
      name: 'AI튜터',
      url: 'ai',
    },
    {
      name: '학생',
      url: 'student',
    },
    {
      name: '튜터',
      url: 'tutor',
    },
    {
      name: '학교/학원/교습소',
      url: 'academy',
    },
    {
      name: '튜터모집',
      url: 'platform',
    },
    {
      name: '학교/학원 신청',
      url: 'myapp',
    },
    {
      name: '홍보영상',
      url: 'promotion',
    },
    {
      name: '수업적용모델',
      url: 'classmodel',
    },
  ]

  return (
    <ul>
      {menuList.map((a, i) => {
        return (
          <li>
            <Link to={a.url}>{a.name}</Link>
          </li>
        )
      })}
    </ul>
  )
}

export default Header
