import React, { useState, useEffect } from 'react'

import '../sass/main.scss'

const banAnimation = true

function Main() {
  const [bannerAni, setBannerAni] = useState(false)
  const [pop, setpop] = useState(true)
  const [pop2, setpop2] = useState(true)
  const close = (e) => {
    e === 1 ? setpop(false) : setpop2(false)
  }
  useEffect(() => {
    setTimeout(() => {
      setBannerAni(true)
    }, 500)
    setBannerAni(true)
  })
  let count = 2
  setInterval(() => {
    if (banAnimation) return
    if (count == 4) {
      count = 1
    }
    for (let i = 1; i < 4; i++) {
      if (count !== i) {
        document.getElementById(`image0${i}`).classList.remove('on')
        document.getElementById(`moImage0${i}`).classList.remove('on')
      }
    }
    document.getElementById(`image0${count}`).classList.add('on')
    document.getElementById(`moImage0${count}`).classList.add('on')
    count++
  }, 4000)

  return (
    <>
      <div className="banner">
        <img src="/image/main/bannerBg.png" width={'100%'} alt="" />

        <div className="content">
          <div>
            <h3>
              AI 개인교사시스템 <br />
              기반 앱 매쓰튜터
            </h3>
            <h4>(Intelligent Tutoring System, ITS)</h4>
            <p className="p" style={{ lineHeight: '1.4' }}>
              수학문제 사진만 찍으면 <br />
              AI가 상세하게 풀어주고 <br />
              개념설명도 쉽게 쫙 쫙 쫙!
            </p>

            <div className="camera">
              <img src="/image/main/main_camera.png" alt="" />
              <p>
                뿐만 아니라 <br />
                <span>기초•유사•심화문제, 계통</span>
                <br />
                <span>개념영상</span>
                <b>도 쫙 쫙 쫙!!</b>
              </p>
            </div>
          </div>

          <div className="slide clip-pc">
            <video
              className="video-clip"
              src="/video/main_1.mp4"
              autoPlay
              loop
              muted
            ></video>
            <img
              id="image01"
              className="on"
              src="/image/main/main_phone01.png"
              alt=""
            />
            <img id="image02" src="/image/main/main_phone02.png" alt="" />
            <img id="image03" src="/image/main/main_phone03.png" alt="" />
            <img src="/image/main/main_pencil.png" className="pencil" alt="" />
          </div>
        </div>

        <div className="moContent">
          <div className="wrap">
            <h3>
              AI 개인교사시스템 <br />
              기반 앱 매쓰튜터
            </h3>
            <h4>(Intelligent Tutoring System, ITS)</h4>
            <ul>
              <li>
                <p className="p">
                  수학문제 사진만 찍으면 <br />
                  AI가 상세하게 풀어주고 <br />
                  개념설명도 쉽게 쫙 쫙 쫙!
                </p>
                <img className="pen" src="/image/main/main_pencil.png" alt="" />
                <p>
                  뿐만 아니라 <br />
                  <span>기초•유사•심화문제, 계통</span>
                  <br />
                  <span>개념영상</span>
                  <b>도 쫙 쫙 쫙!!</b>
                </p>
                <img src="/image/main/main_camera.png" alt="" />
              </li>
              {/* <li className="slides">
                <img
                  id="moImage01"
                  className="on"
                  src="/image/main/main_phone01.png"
                  alt=""
                />
                <img id="moImage02" src="/image/main/main_phone02.png" alt="" />
                <img id="moImage03" src="/image/main/main_phone03.png" alt="" />
              </li> */}
              <li className="mo-phone slides">
                <div className="clip-mo">
                  <video
                    className="video-clip"
                    src="/video/main_1.mp4"
                    autoPlay
                    loop
                    muted
                  ></video>
                  <img
                    className="sized"
                    src="/image/main/main_phone01.png"
                    alt=""
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="imageIcon">
          <img src="/image/main/imageIcon01.png" alt="" />
          <img src="/image/main/imageIcon02.png" alt="" />
        </div>
      </div>

      <div
        className="pop"
        style={pop === false ? { display: 'none' } : { display: 'block' }}
      >
        <img src="/image/pop top_cut.jpg" alt="" />
        <form action="/mail/mail.php" method="post">
          <div>
            <input type="hidden" name="type" value="튜터앱" />
            <input type="text" name="name" placeholder="이름" />
            <input type="text" name="tel" placeholder="연락처" />
          </div>
          <button type="submit">튜터신청하기</button>
        </form>
        <i
          onClick={() => {
            close(1)
          }}
          class="fa-solid fa-xmark"
        ></i>
      </div>
    </>
  )
}

export default Main
