import React, { useEffect, useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import Header from './common/Header'
import Footer from './common/Footer'
import Main from './Main'
import SubPage from '../router/SubPage'
import SlickHome from "./subPage/SlickHome"

import '../sass/common/common.scss'


function App() {
  const params = useParams()
  const [slickMode, setSlickMode] = useState(true) // true: slick, false: default(기존)

  const defaultHome = () => {
    return (
      <>
        <Header></Header>
        <Switch>
          <Route exact path="/">
            <Main></Main>
          </Route>
          <Route exact path="/:pageName">
            <SubPage></SubPage>
          </Route>
        </Switch>
        <Footer/>
      </>
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [params])

  return (
    <div className="App">
      { slickMode ? <SlickHome/> : defaultHome() }
    </div>
  )
}

export default App
