/* eslint-disable no-undef */
// import React, { useEffect } from "react";
// import { Navigation, Pagination } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
//import "swiper/components/navigation/navigation.min.css";
//import "swiper/components/pagination/pagination.min.css";
// import "../../sass/subPage/classmodel.scss";

// function Classmodel() {
//   const imageUrl1 = (image) => {
//     return `/image/subPage/classmodel/${image}.png`;
//   };
//   const imageUrl2 = (image) => {
//     return `/image/subPage/classmodel/${image}.jpg`;
//   };

//   useEffect(() => {
//     setTimeout(() => {
//       document.getElementById(`scroll01`).classList.add("on");
//     }, 100);
//   });
//   window.addEventListener("scroll", () => {
//     if (document.getElementById(`scroll01`).getBoundingClientRect().top < 200) {
//       document.getElementById(`scroll01`).classList.add("on");
//     }
//   });

//   return (
//     <div className="classmodel">
//       <div className="scroll01" id="scroll01">
//         <div className="wrap">
//           <div className="titlebox">
//             <img src={imageUrl1("icon05")} alt="" />
//             <h3 className="title">수업에 적용해보세요</h3>
//           </div>
//           <div className="slideupbox">
//             <img className="none" src={imageUrl1("shop")} alt="" />
//             <iframe
//               src="https://www.youtube.com/embed/3kBAu3jTH-Q"
//               title="YouTube video player"
//               frameborder="0"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//               allowfullscreen
//             ></iframe>
//           </div>
//         </div>
//       </div>

//       <div className="scroll02 blue">
//         <div className="wrap">
//           <Swiper
//             modules={[Navigation, Pagination]}
//             slidesPerView={1}
//             navigation={{
//               prevEl: ".prev",
//               nextEl: ".next",
//             }}
//             loop={true}
//             pagination={{ clickable: true }}
//             onSwiper={(swiper) => console.log(swiper)}
//             onSlideChange={() => console.log("slide change")}
//           >
//             <SwiperSlide>
//               <img src={imageUrl2("0001")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0002")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0003")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0004")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0005")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0006")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0007")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0008")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0009")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0010")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0011")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0012")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0013")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0014")} alt="" />
//             </SwiperSlide>
//             <SwiperSlide>
//               <img src={imageUrl2("0015")} alt="" />
//             </SwiperSlide>
//             <div className="prev"></div>
//             <div className="next"></div>
//           </Swiper>
//           {/* <div className="swiper mySwiper"> */}
//           <div className="swiper-wrapper">
//             <div className="swiper-slide">
//               <img src={imageUrl2("0001")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0002")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0003")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0004")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0005")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0006")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0007")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0008")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0009")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0010")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0011")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0012")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0013")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0014")} alt="" />
//             </div>
//             <div className="swiper-slide">
//               <img src={imageUrl2("0015")} alt="" />
//             </div>
//           </div>
//           <div className="swiper-button-next"></div>
//           <div className="swiper-button-prev"></div>
//           <div className="swiper-pagination"></div>
//           {/* </div>  */}
//         </div>
//       </div>
//     </div>
//   );
// }
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "../../sass/subPage/classmodel.scss";

SwiperCore.use([Navigation, Pagination]);

export default function Classmodel() {
  useEffect(() => {
    setTimeout(() => {
      document.getElementById(`scroll01`).classList.add("on");
    }, 100);
  });

  window.addEventListener("scroll", () => {
    if (document.getElementById(`scroll01`).getBoundingClientRect().top < 200) {
      document.getElementById(`scroll01`).classList.add("on");
    }
  });

  return (
    <div className="classmodel">
      <div className="scroll01" id="scroll01">
        <div className="wrap">
          <div className="titlebox">
            <img src="image/subPage/classmodel/icon05.png" alt="" />
            <h3 className="title">수업에 적용해보세요</h3>
          </div>
          <div className="slideupbox">
            <img
              className="none"
              src="image/subPage/classmodel/shop.png"
              alt=""
            />
            <iframe
              src="https://www.youtube.com/embed/b42Ha7ucit0" // Note the correct URL format
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      <div className="scroll02 blue">
        <div className="notice">
          <i>옆으로 슬라이드하여 확인해보세요!</i>
        </div>
        <div className="swiper-container">
          <div className="swiper-wrapper">
          <Swiper
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          loop={true}
          pagination={{
            el: ".swiper-pagination",
            clickable: true,
          }}
          >
          <SwiperSlide>
            <img src="image/subPage/classmodel/0001.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="image/subPage/classmodel/0002.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="image/subPage/classmodel/0003.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="image/subPage/classmodel/0004.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="image/subPage/classmodel/0005.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="image/subPage/classmodel/0006.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="image/subPage/classmodel/0007.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="image/subPage/classmodel/0008.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="image/subPage/classmodel/0009.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="image/subPage/classmodel/0010.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="image/subPage/classmodel/0011.jpg" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="image/subPage/classmodel/0012.jpg" alt="" />
          </SwiperSlide>
          </Swiper>
          </div>

          {/* Navigation buttons */}
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>

          {/* Pagination */}
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </div>
  );
}



//export default Classmodel;
