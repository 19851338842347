import React from 'react'

const imageList = Array.from({ length: 29 }, (_, i) => i + 1)

const ImageList = () => {

  return <div className='images'>
    {imageList.map((image, index) => {
      return <img key={index} src={`/image/1222/${image}.jpg`} alt="" />
    })}
    </div>;
};

export default ImageList;