import React, { useEffect } from 'react'
import '../../sass/subPage/academy.scss'
import { useLocation, useHistory } from 'react-router-dom'
import ImageList from './ImageList'

const tabs = ['학교, 학원, 교습소', '적용 방법']

function Academy() {
  let history = useHistory()
  const imageUrl = (image) => {
    return `/image/subPage/academy/${image}.png`
  }
  const imageUrl2 = (image) => {
    return `/image/subPage/academy/${image}.jpg`
  }
  const location = useLocation()
  const [tab, setTab] = React.useState('')

  const updateQuery = (newQuery) => {
    const currentQuery = new URLSearchParams(location.search)
    currentQuery.set('tab', newQuery)

    history.push(`${location.pathname}?${currentQuery.toString()}`)
  }
  useEffect(() => {
    const { search } = location
    if (!search) return setTab('0')
    const searchParams = new URLSearchParams(search)
    const tab = searchParams.get('tab')
    setTab(tab === '1' ? '1' : '0')
  }, [location])
  useEffect(() => {
    setTimeout(() => {
      document.getElementById(`scroll01`)?.classList.add('on')
    }, 100)
  })
  window.addEventListener('scroll', () => {
    for (let i = 1; i < 5; i++) {
      if (
        document.getElementById(`scroll0${i}`)?.getBoundingClientRect().top < 200
      ) {
        document.getElementById(`scroll0${i}`)?.classList.add('on')
      }
    }
  })
  if (!tab) return <div className="dummy" />
  return (
    <>
      <div className="academyTabs">
        <div className="wrap">
          {tabs.map((tabName, index) => {
            return (
              <div
                key={index}
                className={`tab ${tab === index.toString() ? 'active' : ''}`}
                onClick={() => {
                  updateQuery(index.toString())
                }}
              >
                {tabName}
              </div>
            )
          })}
        </div>
      </div>
      {tab === '1' ? (
        <div className="academy pdf">
          <ImageList />
        </div>
      ) : (
        <div className="academy">
          <div id="scroll01">
            <div className="wrap">
              <h3 className="title font">
                학교, 학원 수업 활성화를 위한 <br />
                <span>개별 플랫폼 제공</span>
              </h3>
              <ul>
                <li>
                  <img
                    className="pcimg"
                    src={imageUrl('section01_phone')}
                    alt=""
                  />
                  <img
                    src={imageUrl('shop')}
                    alt=""
                    className="iconimage01 none"
                  />
                </li>
                <li>
                  <img src={imageUrl('section01_text')} alt="" />
                  <div>
                    <img src={imageUrl('section01_character(G)')} alt="" />
                    <img src={imageUrl('section01_character(M)')} alt="" />
                    <img
                      className="moimg"
                      src={imageUrl('section01_phone')}
                      alt=""
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="blue" id="scroll02">
            <div className="wrap">
              <h3 className="title font">
                AI에 의한 학교 <br />
                <span>기출문제은행 간단한게 구축</span>
              </h3>
              <p>
                기출문제에 내가 풀이한 답변영상으로 문제은행을 구축하는 과정
              </p>
              <ol className="grid">
                <li>
                  <img src={imageUrl2('section03_AI')} alt="" />
                </li>
                <li>
                  <img src={imageUrl2('section03_tutor')} alt="" />
                </li>
              </ol>
            </div>
          </div>

          <div id="scroll03">
            <div className="wrap">
              <h3 className="title font">
                1:1 및 1:N 전자칠판을 이용한 <br />
                <span>화상수업</span>
              </h3>
              <ol>
                <li>
                  <img src={imageUrl('section04_phone1')} alt="" />
                  라이브 목록
                  <img
                    src={imageUrl('shop')}
                    alt=""
                    className="iconimage02 none"
                  />
                </li>
                <li>
                  <img src={imageUrl('section04_phone2')} alt="" />
                  풀이 화면
                </li>
                <li>
                  <img src={imageUrl('section04_phone3')} alt="" />
                  접속자 리스트
                  <img
                    src={imageUrl('section04_character')}
                    alt=""
                    className="character01"
                  />
                </li>
              </ol>
            </div>
          </div>

          <div className="blue" id="scroll04">
            <div className="wrap">
              <h3 className="title font">
                매쓰튜터에 가입한 이웃 학생들끼리 소통
                <br />
                <span>O2O시스템(당근마켓형)</span>
              </h3>
              <ol>
                <li>
                  <img src={imageUrl('section05_phone1')} alt="" />
                  같은 지역 학생들이 올린 <br />
                  이웃질문방을 통한 연결
                  <img
                    src={imageUrl('section05_character(M)')}
                    alt=""
                    className="character02 pcimg"
                  />
                </li>
                <li>
                  <img src={imageUrl('section05_phone2')} alt="" />
                  같은 지역 학생들에게 <br />
                  쪽지 보내기
                </li>
                <li>
                  <img
                    style={{ zIndex: '1' }}
                    src={imageUrl('section05_phone3')}
                    alt=""
                  />
                  학생들이 이웃튜터를 <br />
                  지정하여 질문
                  <img
                    style={{ zIndex: '1' }}
                    src={imageUrl('section05_character(G)')}
                    alt=""
                    className="character03"
                  />
                  <img
                    style={{ zIndex: '1' }}
                    src={imageUrl('section05_character(M)')}
                    alt=""
                    className="character03 moimg"
                  />
                  <img
                    src={imageUrl('silor')}
                    alt=""
                    className="iconimage03 none"
                  />
                </li>
              </ol>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Academy
