import React from "react";
import "../../sass/subPage/promotion.scss";

function Promotion() {
  const imageUrl = (image) => {
    return `/image/subPage/promotion/${image}.png`;
  }

  return (
    <div className="promotion">
      <div className="section">
        <img src={imageUrl("icon01")} alt="" />
        <p>모르는 수학문제, 스마트폰으로 실시간 개인과외</p>
        <iframe src="https://www.youtube.com/embed/IJyhhxEHZA0" title="모르는 문제를 바로 개인지도 받고 싶을 땐 매쓰튜터!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className="section blue">
        <img src={imageUrl("icon02")} alt="" />
        <p>수학문제 사진만 찍으면 유사심화문제 및 개념영상이 무료로 쫙!</p>
        <iframe src="https://www.youtube.com/embed/xLR3CxDaPYg" title="수학문제 사진만 찍으면 개념영상과 유사심화문제가 무료로 쫙! 매쓰튜터!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className="section">
        <img src={imageUrl("icon03")} alt="" />
        <p>스마트폰만 있으면 모르는 수학문제 한번에 클리어!</p>
        <iframe src="https://www.youtube.com/embed/bhBFRhDwGxo" title="모르는 수학문제는 매쓰튜터와 함께!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className="section blue">
        <img src={imageUrl("icon04")} alt="" />
        <p>이제부터 수학공부는 매쓰튜터와 함께!</p>
        <iframe src="https://www.youtube.com/embed/Txzsm07344Y" title="모르는 수학문제는 매쓰튜터!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
  )
}

export default Promotion;